import React, { useState, ChangeEvent } from 'react';
import Separator from '~/components/Separator';
import { useRegraEmpresa } from '../../RegraDeEmpresaContext';
import { Select, tipoCobranca } from '~/pages/RegraDeEmpresa/protocols';
import { Col, Row } from 'react-bootstrap';
import { Container } from './styles';
import InputMoney from '~/components/Inputs/InputMoney';
import InputSelect from '~/components/Inputs/InputSelect';
import { InputPercent, InputNumber2 } from '~/components/NovosInputs';
import { transformAsCurrency } from '~/utils/functions';
import ToggleDefault from '~/components/ToggleDefault';

export const AbaFinanceiro: React.FC = () => {
  const {
    formRegraEmpresa,
    handleFormRegraEmpresa,
    inicializado,
    formTela: { register, setValue, getValues, control },
  } = useRegraEmpresa();
  const [calcJuros, setCalcJuros] = useState<boolean>(false);
  return (
    <>
      <Container>
        <Row>
          <Separator labelText="Tesouraria" />
          <Col sm={12} md={3}>
            <InputMoney
              label="Tolerância Quebra/Sobra:"
              min={0}
              placeholder="0,00"
              onChange={(value: string) => {
                setValue('val_tolerancia_tes', value?.replace('-', ''));
                handleFormRegraEmpresa({
                  ...formRegraEmpresa,
                  val_tolerancia_tes: {
                    ...formRegraEmpresa.val_tolerancia_tes,
                    value: value?.replace('-', ''),
                    isInvalid: transformAsCurrency(value) === 0,
                  },
                });
              }}
              isRequired={formRegraEmpresa.val_tolerancia_tes.isRequired}
              setInvalid={formRegraEmpresa.val_tolerancia_tes.isInvalid}
              iniInicializado={inicializado}
              value={formRegraEmpresa.val_tolerancia_tes.value}
            />
          </Col>
          <Col sm={12} md={3}>
            <InputSelect
              label="Gerar Título:"
              onChange={(value: Select) => {
                setValue('tipo_cobranca_tes', value);
                handleFormRegraEmpresa({
                  ...formRegraEmpresa,
                  tipo_cobranca_tes: {
                    ...formRegraEmpresa.tipo_cobranca_tes,
                    value,
                    isInvalid: value.value === undefined,
                  },
                });
              }}
              options={tipoCobranca}
              isRequired={formRegraEmpresa.tipo_cobranca_tes.isRequired}
              setInvalid={formRegraEmpresa.tipo_cobranca_tes.isInvalid}
              iniInicializado={inicializado}
              value={
                tipoCobranca.find((item: Select) => {
                  return (
                    item.value ===
                    formRegraEmpresa.tipo_cobranca_tes.value.value
                  );
                }) ?? {
                  value: undefined,
                  label: 'Selecione',
                }
              }
            />
          </Col>
        </Row>
        <Row>
          <Separator labelText="Cálculo de Juros" />
          <div className="calculate-container">
            <div className="space mt-4">Calcular</div>
            <Col className="space" sm={12} md={2}>
              <InputPercent
                label=""
                placeholder="0,00"
                min={0}
                step="2"
                max={100}
                name="per_juros_dia"
                register={register}
                control={control}
                isError={false}
              />
            </Col>
            <div className="space mt-4">de juros ao dia após</div>
            <Col className="space mt-4" sm={12} md={1}>
              <InputNumber2
                label=""
                max={31}
                min={1}
                maxLength={2}
                placeholder="0"
                name="qtd_dias_carencia_juros"
                register={register}
                control={control}
                isError={false}
                onInput={(ev: ChangeEvent<HTMLInputElement>) => {
                  ev.target.value = ev.target.value.replace(/\D/, '');
                  if (Number(ev.target.value) < 1) {
                    ev.target.value = '1';
                  }
                  if (Number(ev.target.value) > 31) {
                    ev.target.value = '31';
                  }
                  setValue('qtd_dias_carencia_juros', Number(ev.target.value));
                }}
              />
            </Col>
            <div className="space mt-4">dias vencido.</div>
          </div>
        </Row>
        <Row>
          <Col className="mt-4" sm={12}>
            <ToggleDefault
              labelText="Não calcular juros por vencimento no fim de semana e feriado?"
              setChecked={calcJuros || getValues('flg_n_calc_juros_fds')}
              onSwitch={() => {
                setCalcJuros(!calcJuros);
                setValue('flg_n_calc_juros_fds', !calcJuros);
              }}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};
